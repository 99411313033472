/*

Welcome to the Fontawesome Bundler 

for more info reference here

https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core
https://fontawesome.com/how-to-use/with-the-api/setup/getting-started


if you aren't using a weight at all it needs to be completely commented out

	*/


import { config , library, dom, icon } from 'Node/@fortawesome/fontawesome-svg-core';
config.searchPseudoElements = true

//light
import { 

	faTimes as falTimes,
	faCalendarAlt,
	faCart,
	faEllipsisV,
	faLongArrowRight as falLongArrowRight,
	faLongArrowLeft,
	faMapMarkerAlt,
	faTachometer,
	faCalendarDay,
	faChevronLeft,
	faChevronRight,
	faTicket,
	faTv as falTv,
	faTrophy,
	faUsers,
	faClock as falClock,
	faFilePdf,
	faFileAlt,
	faPlayCircle,
	faCalendarPlus,
	faMapMarkedAlt as falMapMarkedAlt,
	faThumbsUp,
	faShareSquare,
	faFileDownload as	falFileDownload,
	faPlus,
	faSignOut,
	faIdBadge,
	faUserGraduate,
	faUser,
	faCalendar,
	faBell,
	faPhone as falPhone,
	faMapMarkerAlt as falMapMarkerAlt,
	faBuilding,
	faIdCardAlt,
	faEnvelope as falEnvelope,
	faLockAlt,
	faArrowToLeft,
	faShoppingCart as falShoppingCart,
	faStar as falStar,
	faLink as falLink,

} from 'Node/@fortawesome/pro-light-svg-icons';

// //regular
import { 
	faChevronDown,
	faMapMarker as farMapMarker,
	faLongArrowRight as farLongArrowRight,
	faSpinner,
	faLongArrowAltRight,
	faLongArrowAltLeft,
	faCheck as farCheck,

} from 'Node/@fortawesome/pro-regular-svg-icons';

//solid
import { 
	faInfoCircle,
	faEdit,
	faEnvelope as fasEnvelope,
	faPhone as fasPhone,
	faSearch,
	faCaretDown,
	faCaretUp,
	faTimes as fasTimes,
	faTachometerFastest,
	faCommentAltLines,
	faTachometerSlowest,
	faTachometerFast,
	faUserCircle,
	faShoppingCart as fasShoppingCart,
	faArrowAltSquareUp,
	faEnvelopeSquare,
	faHourglassHalf,
	faExclamationTriangle,
	faBadgeCheck,
	faCheck as fasCheck,
	faClock as fasClock,
	faInfoSquare,
	faTv as fasTv,
	faMapMarkerAlt as fasMapMarkerAlt,
	faFileDownload as	fasFileDownload,
	faLink as fasLink,

} from 'Node/@fortawesome/pro-solid-svg-icons';


// Brands
import { 
	faTwitterSquare,
	faFacebookSquare,
	faFacebookF,
	faInstagram,
	faLinkedin,
	faLinkedinIn,
	faTwitter,

	

} from 'Node/@fortawesome/free-brands-svg-icons';


library.add( 


	// arrows
	falLongArrowRight,
	farLongArrowRight,
	faLongArrowLeft,
	faChevronDown,
	falTimes,
	fasTimes,
	faChevronLeft,
	faChevronRight,
	faCaretDown,
	faCaretUp,
	faArrowAltSquareUp,
	farCheck,
	fasCheck,
	faLongArrowAltRight,
	faLongArrowAltLeft,
	
	// event icons
	faCalendarAlt,
	falShoppingCart,
	fasShoppingCart,
	faTicket,	
	faCalendarAlt,

	falMapMarkedAlt,

	farMapMarker,
	falMapMarkerAlt,
	fasMapMarkerAlt,
	faInfoCircle,

	faCalendarDay,

	faCalendarPlus,


	//cpd
	faTachometerFastest,
	faUserCircle,
	faTachometerSlowest,
	faTachometerFast,
	faTachometer,
	faHourglassHalf,
	faExclamationTriangle,
	faBadgeCheck,

	//docs
	faEllipsisV,
	faFileAlt,
	faFilePdf,
	falFileDownload,


	//contact
	fasEnvelope,
	falEnvelope,
	faEdit,
	falPhone,
	fasPhone,

	//general
	faUser,
	faInfoSquare,
	falStar,
	falTv,
	fasTv,
	faPlayCircle,
	faUsers,
	faThumbsUp,
	faShareSquare,
	faPlus,
	faSearch,
	faSignOut,
	faIdCardAlt,
	faLockAlt,
	faArrowToLeft,
	faIdBadge,
	faUserGraduate,
	faCalendar,
	faBell,
	faBuilding,
	faCommentAltLines,
	faSpinner,
	fasClock,
	falClock,
	fasLink,
	falLink,
	faTrophy,
	//social
	faFacebookSquare,
	faTwitterSquare,
	faEnvelopeSquare,

	faLinkedin,
	faTwitter,
	faFacebookF,
	faInstagram,
	faLinkedinIn,

	);



//Brands
// import { 



// } from 'Node/@fortawesome/free-brands-svg-icons';



// insert css to reference for style sheets
const css = dom.css()



dom.insertCss(css)

dom.watch()